
const JuranQuote =
  <blockquote className="relative">
  <div className="text-2xl leading-9 font-medium text-gray-900">
    <p>
      Toda mejora ocurre proyecto tras proyecto y de ninguna otra forma    
    </p>
  </div>
  <footer className="mt-8">
    <div className="flex">
      <div className="flex-shrink-0 lg:hidden">
        <img
          className="h-12 w-12 rounded-full"
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </div>
      <div className="ml-4 lg:ml-0">
        <div className="text-base font-medium text-gray-900">Joseph M. Juran</div>
        <div className="text-base font-medium text-indigo-600">Líder del movimiento de la Gestión de Calidad </div>
      </div>
    </div>
  </footer>
  </blockquote>


export default function Company() {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
            alt=""
          />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Trabaja con nosotros</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Nuestro Proceso
            </h3>
            <div className="mt-5 prose prose-indigo text-gray-500">
              <p>
                Todas nuestras consultorías se llevan a cabo mediante proyectos cortos y con objetivos claros
              </p>
              <p>
                Cada proyecto exitoso da pié a más proyectos de mejora
              </p>
              <p className="mt-8 text-lg text-gray-500">
                {JuranQuote}
              </p>
              <p>
                Cómo aseguramos el éxito
              </p>
              <ul>
                <li>Los usuarios son parte del diseño, desarrollo y puesta en marcha de los proyectos</li>
                <li>Los proyectos son cortos y con beneficios claros</li>
                <li>Estamos convencidos que una cadena exitosa de proyectos es la mejor forma de transformar una organización</li>
              </ul>
              <p>
                Llámenos para una evaluación sin compromiso de sus necesidades de mejora
              </p>
              <h3>En qué nos diferenciamos</h3>
              <p>
                Entendemos la razón de ser de las empresas. La tecnología es una ventaja competitiva siempre y cuando se aplique a lograr los objetivos
                de negocio y se use correctamente.
              </p>
              <p>
                 Todos los proyectos en los que nos involucramos tienen un beneficio claro para el negocio y nos aseguramos que nuestros clientes los 
                 puedan utilizar y mantener por sí mismos. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
