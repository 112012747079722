/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Experiencia Empresarial',
    description: 'Tenemos proyectos en todas las ramas: servicios, manufactura, distribución, eCommerce',
  },
  { name: 'Proyectos cortos...', description: 'Nuestra filosofía: Cualquier solución permanente empieza con resultados inmediatos.' },
  {
    name: '¿Por dónde empezar?',
    description: 'Le ayudamos a gestionar su proceso de mejora',
  },
  { name: 'Operaciones', description: 'Automatice la Faturación Electrónica, las Ventas y el Marketing' },
  { name: 'Finanzas', description: '¿Obtiene las cifras y comparativos que le ayuden a la toma de decisiones?' },
  { name: 'Ventas', description: '¿Cuenta con un CRM? ¿Integrado a su sistema de Marketing?' },
  { name: 'Marketing', description: 'Vea lo sencillo que es implantar un sistea de Automatización de Marketing' },
  { name: 'Estrategia de Negocio', description: 'Implante un sistema de Inteligencia de Negocio' },
]

export default function Consultoria() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Toda la asesoría que necesita</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Mejora de Procesos</p>
          <p className="mt-4 text-lg text-gray-500">
            Llámenos para una evaluación sin compromiso de sus necesidades.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
