import React from 'react'
import { useForm } from 'react-hook-form'

function ExampleForm() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
  
    console.log(watch("example")); // watch input value by passing the name of it
  
    return (
      /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input defaultValue="test" {...register("example")} />
        
        {/* include validation with required or other standard HTML validation rules */}
        <input {...register("exampleRequired", { required: true })} />
        {/* errors will return when field validation fails  */}
        {errors.exampleRequired && <span>This field is required</span>}
        
        <input type="submit" />
      </form>
    );  
}

function EmailForm() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
  
    console.log(watch("correo")); // watch input value by passing the name of it

    return (
        <div className="mt-10 sm:mt-12">
        <form onSubmit={handleSubmit(onSubmit)} className="sm:max-w-xl sm:mx-auto lg:mx-0">
          <div className="sm:flex">
            <div className="min-w-0 flex-1">
              <label htmlFor="email" className="sr-only">
                Correo
              </label>

              <input  {...register("correo")} 
                placeholder="Ingresa tu correo"
                className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"              
              />

              {/* <input
                id="email"
                type="email"
                placeholder="Ingresa tu correo"
                className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
              /> */}

            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className="block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
              >
                Enviar
              </button>
            </div>
          </div>
          <p className="mt-3 text-sm text-gray-300 sm:mt-4">
            Solicita nuestra Guía de Mejora en la Toma de Decisiones
          </p>
        </form>
      </div>
    )
    
}


export default EmailForm
