/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Soluciones en la Nube',
    description:
      'No inviertas en hardware. Obten los recursos de cómputo que necesitas al instante. Por unos minutos o de manera permanente',
    icon: GlobeAltIcon,
  },
  {
    name: 'Integra tus sistemas',
    description:
      'Conecta el CRM con la Página Web. Tu sistema de gestión ERP con la Tienda Web. Tu Punto de Venta con la Factura Electrónica',
    icon: ScaleIcon,
  },
  {
    name: 'Serverless',
    description:
      'Resuelve hoy un problema. Con PowerApps de Microsoft y Cloud Functions puedes crear una automatización en minutos',
    icon: LightningBoltIcon,
  },
  {
    name: 'Mantente en contacto con tus clientes',
    description:
      '¿Cómo das servicio a tus cliente? Automatiza el soporte, marketing y seguimiento de ventas',
    icon: AnnotationIcon,
  },
]

export default function DesarrolloSoftware() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Software a la Medida</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Desarrollo de Software
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            ¡Resuelve un problema hoy! Con las tecnologías de PowerApps y Cloud Functions no requieres de invertir en infrastructura. 
            Puedes automatizar un proceso en minutos y acceder al mismo desde cualquier lugar del mundo.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
