import { Link } from 'react-router-dom'

export default function AvisoPrivacidad() {
    return (
      <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Mandos respeta tu privacidad </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Aviso de Privacidad
              </p>
            </div>
          </div>
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                  <p>
                    Nuestros principios son:
                    <ul>
                      <li>
                        <b>Legalidad:</b> Solo recopilaremos sus Datos personales para fines específicos, explícitos y legítimos.
                      </li>       
                      <li>
                        <b>Minimización de datos:</b> Limitamos la recogida de datos de carácter personal a lo que es estrictamente relevante y necesario para los fines 
                        para los que se han recopilado.
                      </li>
                      <li>
                        <b>Limitación de la Finalidad:</b> Solo recogeremos sus datos personales para los fines declarados y solo según sus deseos.
                      </li>
                      <li>
                        <b>Seguridad de los Datos:</b> Aplicamos las medidas técnicas y organizativas adecuadas y proporcionales a los riesgos para garantizar que sus datos 
                        no sufran daños, tales como divulgación o acceso no autorizado, la destrucción accidental o ilícita o su pérdida accidental o alteración y cualquier 
                        otra forma de tratamiento ilícito.
                      </li>
                      <li>
                        <b>Conservación:</b> Conservamos sus datos personales de manera legal y apropiada y solo mientras es necesario para los fines para los que se han recopilado.
                      </li>
                      <li>
                        <b>Terceros:</b> El acceso y transferencia de datos personales a terceros se llevan a cabo de acuerdo con las leyes y reglamentos aplicables
                        y con las garantías contractuales adecuadas.
                      </li>
                      <li>
                        <b>Marketing Directo y cookies:</b> Cumplimos con la legislación aplicable en materia de publicidad y cookies.                                          
                      </li>  
                    </ul>

                  </p>
                </div>
                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                  <p>
                    <p><b>INFORMACIÓN LEGAL</b></p>
                    Los requisitos de esta Política complementan, y no reemplazan, cualquier otro requisito existente bajo la ley de protección de datos aplicable, que será la que prevalezca en cualquier caso.
                  </p>
                  <p>
                    Esta Política está sujeta a revisiones periódicas y la empresa puede modificarla en cualquier momento. Cuando esto ocurra, le avisaremos de cualquier cambio y le pediremos que 
                    vuelva a leer la versión más reciente de nuestra Política y que confirme su aceptación.                  
                  </p>
                </div>
              </div>
              <div className="mt-8 inline-flex rounded-md shadow">
                <Link
                  to="/"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Ir a Página Principal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  