/* This example requires Tailwind CSS v2.0+ */
const faqs = [
    {
      id: 1,
      question: "¿Qué debo de entender por Inteligencia de Negocios (BI)?",
      answer:
        "Son los procesos y tecnologías para el manejo y proceso de datos que utiliza una organización para mejorar la toma de decisiones y aumentar su ventaja competitiva",
    },
    {
     id: 2,
     question: "¿Qué es Power BI?",   
     answer:
     "Es una plataforma de Autoservicio de Inteligencia de Negocio que permite a cualquier persona de la organización obtener la información requerida sin depender del Area de Sistemas",
 },
    {
        id: 3,
        question: "BI Descriptivo",
        answer:
        "¿Qué pasó? ¿Dónde hay problemas o áreas de oportunidad? La herramienta de BI te ayuda a detectar las áreas de interés.",
    },
    {
        id: 4,
        question: "BI para Diagnóstico",
        answer:
        "Una vez que detectamos las áreas de interés, el siguiente paso es determinar las causas por las que se dieron",
    },
    {
        id: 5,
        question: "BI Predictivo",
        answer:
        "El siguiente paso lógico es poder definer con cierto grado de certeza lo que nos depara el futuro de acuerdo a la situación actual",
    },
    {
        id: 6,
        question: "BI Prescriptivo",
        answer:
        "Si logramos recrear el modelo de negocios de la organización con nuestra herramienta de BI, entonces tenemos claridad en qué medidas hay que tomar",
    },
          // More questions...
  ]
  
  export default function InteligenciaNegocios() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center">¿Qué es la Inteligencia de Negocios (BI)?</h2>
          <div className="mt-12">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  