/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Power BI',
    description: 'La Solución de Inteligencia de Negocios más extendida y fácil de usar',
  },
  {
    name: 'OKR',
    description: 'La metodología en boga para establecer objetivos empresariales',
  },
  {
    name: 'Inbound Marketing',
    description: '¿Qué estás haciendo para que te conozcan? Descubre cómo atraer clientes',
  },
  {
    name: 'Desarrollo de Software',
    description: 'Desarrollo de Software a la medida de acuerdo a tus necesidades',
  },
  {
    name: 'Power Apps',
    description: 'Automatiza, crea agentes virtuales, sincroniza tus sistemas',
  },
  {
    name: 'Cloud Computing',
    description: 'Migra tu infraestructura a la nube. No inviertas en CAPEX',
  },
  {
    name: 'Auditoría ERPs',
    description: '¿Qué tanto estás aprovechando tus sistemas empresariales?',
  },
  {
    name: 'eCommerce',
    description: 'Te asesoramos para que pongas tu tienda en línea',
  },
]

export default function Tecnologias() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Una solución completa</h2>
          <p className="mt-4 text-lg text-gray-500">
            Mejora los resultados de tu empresa. Todo lo que necesites para recabar, analizar y reportar datos
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-indigo-400" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
