import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from './Components/Header'
import Footer from './Components/Footer'
import ScrollToTop from './Components/ScrollToTop'
import Home from './Views/Home'
import Servicios from './Views/Servicios'
import Tecnologias from './Views/Tecnologias'
import InteligenciaNegocios from './Views/InteligenciaNegocios'
import DesarrolloSoftware from './Views/DesarrolloSoftware'
import Company from './Views/Company'
import Contact from './Views/Contact'
import AvisoPrivacidad from './Views/AvisoPrivacidad'
import NotFound from './Views/NotFound'
import TestForm from './Views/TestForm'
import Consultoria from './Views/Consultoria'

function App() {
  return (
    <>
      <Router>
          <div className="min-h-screen">
            <div className="relative overflow-hidden">
              <Header />
              <ScrollToTop />
              <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/servicios">
                    <Servicios />
                </Route>
                <Route exact path="/tecnologias">
                    <Tecnologias />
                </Route>
                <Route exact path="/inteligencia-negocios">
                    <InteligenciaNegocios />
                </Route>
                <Route exact path="/consultoria">
                    <Consultoria />
                </Route>
                <Route exact path="/desarrollo-software">
                    <DesarrolloSoftware />
                </Route>
                <Route exact path="/company">
                    <Company />
                </Route>
                <Route exact path="/aviso-privacidad">
                    <AvisoPrivacidad />
                </Route>
                <Route exact path="/contacto">
                    <Contact />
                </Route>
{/* TestForm */}
                <Route exact path="/test-form">
                    <TestForm />
                </Route>

                <Route component={NotFound} />     
              </Switch>
              <Footer />
            </div>
          </div>    

      </Router>
    </>
  )
}

export default App
